import { useState } from 'react';
import { FeatureFlag, PreferenceTab } from '../../constants';
import { useFeature } from 'flagged';

export const usePreferences = () => {
  const [activeItem, setActiveItem] = useState(PreferenceTab.PersonalInfo);

  const isPreferencesPageEnabled = useFeature(FeatureFlag.PreferencesPage);

  return {
    isPreferencesPageEnabled,
    activeItem,
    setActiveItem
  };
};
