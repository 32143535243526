import { t } from '@transifex/native';
import { iconWarningGlyph } from '@wfp/icons';
import { Icon } from '@wfp/ui';

interface ErrorProps {
  error: string | undefined;
  className?: string;
}

export const Error = ({ error, className = '' }: ErrorProps) =>
  error ? (
    <div className={`wfp--form-requirement invalid ${className}`}>
      <Icon
        icon={iconWarningGlyph}
        fill="var(--support-01)"
        description="Error message icon"
      />{' '}
      <span>{t(error)}</span>
    </div>
  ) : null;
