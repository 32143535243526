import { useState } from 'react';
import { useHelpSections } from '../../services';

export const useSupport = () => {
  const [activeItem, setActiveItem] = useState(0);

  const { data: helpSections = [], isLoading: isHelpSectionsLoading } =
    useHelpSections();

  return {
    activeItem,
    setActiveItem,
    helpSections,
    isHelpSectionsLoading
  };
};
