import { useMemo } from 'react';
import { useAuth } from 'react-oidc-context';
import { useSearchParams } from 'react-router-dom';
import { SIGN_IN_CALLBACK_RETURN_URL } from '../constants';

export const useSigninRedirect = () => {
  const { signinRedirect } = useAuth();

  const [searchParams] = useSearchParams();

  const returnUrl = useMemo(
    () => searchParams.get(SIGN_IN_CALLBACK_RETURN_URL),
    [searchParams]
  );

  return {
    signinRedirect: () =>
      signinRedirect({
        state: { [SIGN_IN_CALLBACK_RETURN_URL]: returnUrl }
      })
  };
};
