import {
  Button,
  MainNavigation,
  MainNavigationItem,
  SubNavigation,
  SubNavigationContent,
  SubNavigationGroup,
  SubNavigationHeader,
  SubNavigationItem,
  SubNavigationList,
  SubNavigationTitle,
  User,
  Text,
  Link
} from '@wfp/ui';
import { T, useT } from '@transifex/react';
import { useEnv, useOnlineStatus } from '../../../context';
import { OfflineBanner } from '../OfflineBanner';
import { useNavbar } from './Navbar.hooks';
import './Navbar.scss';
import { hasPermission } from '../../../helpers';
import { Applications } from '@partnerconnect-monorepo/types';
import { Permission } from '../../../utils/permissions';

export const Navbar = () => {
  const { onlineStatus } = useOnlineStatus();
  const { deployedOnRootPath, appName } = useEnv();
  const t = useT();
  const {
    userProfile,
    logout,
    setLocale,
    locale,
    languages,
    signinRedirect,
    canAccessInvoicing,
    canAccessPartnershipsAndFinancesUsers,
    canAccessReportingUsers,
    isUsersManagementAccessed,
    userRolesTitle,
    username,
    isPreferencesPageEnabled
  } = useNavbar();

  return (
    <>
      <MainNavigation
        className="navbar"
        mobilePageWidth="full"
        pageWidth="lg"
        logo={
          <div
            className="navbar navbar__link"
            data-testid="navbar-partner-user-container"
          >
            <Link
              href="/"
              data-testid="navbar-partner-partnership-section"
              onClick={(e) => {
                if (appName !== Applications.Home) {
                  e.preventDefault();
                  window.location.assign('/');
                }
              }}
            >
              <h3 data-testid="navbar-partner-title">
                <T _str="Partner Connect" _tag="navbar" />
              </h3>
            </Link>
          </div>
        }
      >
        {userProfile ? (
          deployedOnRootPath && appName !== Applications.Home ? (
            <MainNavigationItem>
              <Link
                href="/"
                className={`navbar__link--active ${
                  !onlineStatus ? 'offline-link' : ''
                }`}
                data-testid={`navbar-partner-${appName}`}
              >
                <T _str={appName} _tag="navbar" />
              </Link>
            </MainNavigationItem>
          ) : (
            <>
              {hasPermission(
                userProfile?.permissions,
                Permission.CanViewMonthlyReports
              ) && (
                <MainNavigationItem>
                  <Link
                    href="/reporting/"
                    className={`${
                      appName === Applications.Reporting &&
                      !isUsersManagementAccessed
                        ? 'navbar__link--active'
                        : ''
                    } ${!onlineStatus ? 'offline-link' : ''}`}
                    data-testid="navbar-partner-fla"
                    onClick={(e) => {
                      if (appName !== Applications.Reporting) {
                        e.preventDefault();
                        window.location.assign('/reporting/');
                      }
                    }}
                  >
                    <T _str="Reports" _tag="navbar" />
                  </Link>
                </MainNavigationItem>
              )}
              {hasPermission(
                userProfile?.permissions,
                Permission.CanViewPartnershipOpportunity
              ) && (
                <MainNavigationItem>
                  <Link
                    href="/partnerships/"
                    className={`${
                      appName === Applications.Fla && !isUsersManagementAccessed
                        ? 'navbar__link--active'
                        : ''
                    } ${!onlineStatus ? 'offline-link' : ''}`}
                    data-testid="navbar-partner-monthly-reports-tab"
                    onClick={(e) => {
                      if (appName !== Applications.Fla) {
                        e.preventDefault();
                        window.location.assign('/partnerships/');
                      }
                    }}
                  >
                    <T _str="Partnerships" _tag="navbar" />
                  </Link>
                </MainNavigationItem>
              )}
              {canAccessInvoicing && (
                <MainNavigationItem>
                  <Link
                    href="/invoicing/"
                    className={`${
                      appName === Applications.Invoicing
                        ? 'navbar__link--active'
                        : ''
                    } ${!onlineStatus ? 'offline-link' : ''}`}
                    onClick={(e) => {
                      if (appName !== Applications.Invoicing) {
                        e.preventDefault();
                        window.location.assign('/invoicing/');
                      }
                    }}
                  >
                    <T _str="Finances" _tag="navbar" />
                  </Link>
                </MainNavigationItem>
              )}
            </>
          )
        ) : (
          <MainNavigationItem>
            <Button
              small
              kind="navigation"
              onClick={() => signinRedirect()}
              aria-label="Login"
            >
              <T _str="Login" _tag="navbar" />
            </Button>
          </MainNavigationItem>
        )}
        {(canAccessReportingUsers || canAccessPartnershipsAndFinancesUsers) && (
          <MainNavigationItem
            className="wfp--main-navigation__user sub-navigation-item"
            subNavigation={
              <SubNavigation>
                <SubNavigationContent>
                  <SubNavigationList>
                    {canAccessReportingUsers && (
                      <SubNavigationItem>
                        <Link
                          href="/reporting/users/"
                          onClick={(e) => {
                            if (appName !== Applications.Reporting) {
                              e.preventDefault();
                              window.location.assign('/reporting/users/');
                            }
                          }}
                        >
                          <T _str="Reporting" />
                        </Link>
                      </SubNavigationItem>
                    )}
                    {canAccessPartnershipsAndFinancesUsers && (
                      <SubNavigationItem>
                        <Link
                          href="/partnerships/users/"
                          onClick={(e) => {
                            if (appName !== Applications.Fla) {
                              e.preventDefault();
                              window.location.assign('/partnerships/users/');
                            }
                          }}
                        >
                          <T _str="Partnerships, Finances" />
                        </Link>
                      </SubNavigationItem>
                    )}
                  </SubNavigationList>
                </SubNavigationContent>
              </SubNavigation>
            }
          >
            <Link
              {...(isUsersManagementAccessed && {
                className: 'navbar__link--active'
              })}
            >
              <T _str="Users" _tag="navbar" />
            </Link>
          </MainNavigationItem>
        )}
        {userProfile && (
          <MainNavigationItem
            className="wfp--main-navigation__user sub-navigation-item"
            data-testid="navbar-partner-user-container"
            subNavigation={
              <SubNavigation data-testid="navbar-partner-user-expanded-container">
                <SubNavigationHeader>
                  <SubNavigationTitle data-testid="navbar-welcome-user">
                    {t('Welcome, {username}', {
                      username,
                      _tag: 'navbar'
                    })}
                  </SubNavigationTitle>
                </SubNavigationHeader>
                {userProfile.email && (
                  <SubNavigationContent className="sub-navigation-content-container">
                    {userRolesTitle && (
                      <div className="sub-navigation-content-item">
                        <Text kind="p">
                          <T _str="Role" />:
                        </Text>
                        <Text kind="h6">{userRolesTitle}</Text>
                      </div>
                    )}
                    {(userProfile.reportingCountry ||
                      userProfile.partnershipsCountry) && (
                      <div className="sub-navigation-content-item">
                        <Text kind="p">
                          <T _str="Country" />:
                        </Text>
                        <Text kind="h6">
                          {userProfile.reportingCountry?.label ??
                            userProfile.partnershipsCountry?.name}
                        </Text>
                      </div>
                    )}
                    {userProfile.partnerName && (
                      <div className="sub-navigation-content-item">
                        <Text kind="p">
                          <T _str="Organization" />:
                        </Text>
                        <Text kind="h6">{userProfile.partnerName}</Text>
                      </div>
                    )}
                    <div className="sub-navigation-content-item">
                      <Text kind="p">
                        <T _str="Email" />:
                      </Text>
                      <Text kind="h6">{userProfile.email}</Text>
                    </div>
                  </SubNavigationContent>
                )}
                <div className="sub-navigation-content-footer">
                  <Button
                    small
                    onClick={logout}
                    data-testid="navbar-logout-button"
                  >
                    <T _str="Logout" _tag="navbar" />
                  </Button>
                  {isPreferencesPageEnabled && (
                    <Button
                      small
                      kind="secondary"
                      onClick={() => window.location.assign('/preferences/')}
                    >
                      <T _str="Preferences" />
                    </Button>
                  )}
                </div>
              </SubNavigation>
            }
          >
            <User
              alt={username}
              name={t('Welcome, {username}', {
                username
              })}
              ellipsis
            />
          </MainNavigationItem>
        )}
        <MainNavigationItem>
          <a href="/support">
            <T _str="Help" />
          </a>
        </MainNavigationItem>
        {languages.length > 0 && (
          <MainNavigationItem
            className="wfp--main-navigation__user"
            data-testid="navbar-user-container"
            subNavigation={
              <SubNavigation>
                <SubNavigationHeader>
                  <SubNavigationTitle>
                    {t('Choose language', { _tag: 'navbar' })}
                  </SubNavigationTitle>
                </SubNavigationHeader>
                <SubNavigationContent>
                  <SubNavigationList>
                    <SubNavigationGroup>
                      {languages?.map(
                        ({ code, localized_name }, languageIdx) => (
                          <SubNavigationItem key={languageIdx}>
                            <Button
                              kind="ghost"
                              small
                              onClick={() => setLocale(code)}
                            >
                              <T _str={localized_name} _tag="navbar" />
                            </Button>
                          </SubNavigationItem>
                        )
                      )}
                    </SubNavigationGroup>
                  </SubNavigationList>
                </SubNavigationContent>
              </SubNavigation>
            }
          >
            <span className="locale">{locale}</span>
          </MainNavigationItem>
        )}
      </MainNavigation>
      {!onlineStatus && <OfflineBanner />}
    </>
  );
};
