import { IRouteProps } from '@partnerconnect-monorepo/types';
import { lazy } from 'react';

const Home = lazy(() => import('@partnerconnect-monorepo/home-lib/pages/Home'));

export const routes: Array<IRouteProps> = [
  {
    path: '',
    component: Home,
    isDefault: true
  }
];
