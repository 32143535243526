import { T } from '@transifex/react';
import { t } from '@transifex/native';
import {
  SecondaryNavigation,
  Wrapper,
  SecondaryNavigationTitle,
  Item,
  Module,
  Sidebar,
  SidebarScroll,
  Text
} from '@wfp/ui';
import { APIHelpSection } from '@partnerconnect-monorepo/types';
import './Support.scss';

interface SupportViewProps {
  activeItem: number;
  setActiveItem: (itemIdx: number) => void;
  helpSections: Array<APIHelpSection>;
}

export const SupportView = ({
  activeItem,
  setActiveItem,
  helpSections
}: SupportViewProps) => {
  return (
    <>
      <SecondaryNavigation>
        <SecondaryNavigationTitle>
          <T _str="Partner Connect support" />
        </SecondaryNavigationTitle>
      </SecondaryNavigation>
      <Wrapper pageWidth="lg">
        <Module className="module">
          <Sidebar
            className="sidebar"
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            active={activeItem}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            sidebar={
              <SidebarScroll>
                {helpSections.map((item, index) => (
                  <Item
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    kind="horizontal"
                    key={`${item.title}-${index}`}
                    data-testid={`${item.title}-ID`}
                    title={t(item.title)}
                    wrapper="sidebar"
                    active={activeItem === index}
                    onClick={() => setActiveItem(index)}
                  />
                ))}
              </SidebarScroll>
            }
          >
            <div className="sidebar-item-content">
              <div>
                <Text kind="h4" className="mb-1">
                  {t(helpSections[activeItem].title)}
                </Text>
                <div
                  dangerouslySetInnerHTML={{
                    __html: helpSections[activeItem].body
                  }}
                ></div>
              </div>
            </div>
          </Sidebar>
        </Module>
      </Wrapper>
    </>
  );
};
