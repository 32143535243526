import { NotificationFrequency } from '@partnerconnect-monorepo/types';
import { NOTIFICATION_LABEL, NotificationFrequencyLabel } from '../constants';
import { t } from '@transifex/native';

export const mapFrequencyToOptionElement = (
  frequency: NotificationFrequency
) => ({
  value: frequency,
  label: t(NotificationFrequencyLabel[frequency])
});

export const getNotificationLabel = (notificationCode: string) =>
  NOTIFICATION_LABEL[notificationCode] ?? notificationCode;
