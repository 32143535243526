import { T } from '@transifex/react';
import { Icon, Story, Wrapper } from '@wfp/ui';
import { iconWarningSolid } from '@wfp/icons';

export const NoAccess = () => {
  return (
    <Wrapper pageWidth="lg" spacing="md">
      <Story className="wfp--story__center wfp--story__full-height">
        <Icon
          description="You do not have access"
          icon={iconWarningSolid}
          fill="#0a6eb4"
          width="100"
          height="100"
          style={{
            marginBottom: '3rem'
          }}
        />
        <h1 className="wfp--story__title">
          <T _str="No access" />
        </h1>
        <p>
          <T _str="Sorry, the page you are trying to access either does not exist or you are not authorized to access it." />
          <br />
          <T _str="Go back to" />{' '}
          <a href="/">
            <T _str="Homepage" />
          </a>
        </p>
      </Story>
    </Wrapper>
  );
};
