import {
  createContext,
  FC,
  PropsWithChildren,
  useContext,
  useEffect,
  useState
} from 'react';

interface ContextProvidersContextProps {
  providers: Array<FC<any>>;
  addProviders: (p: Array<FC<any>>) => void;
}

const ContextProvidersContext = createContext<ContextProvidersContextProps>({
  providers: [],
  addProviders: () => void 0
});

export function ContextsProvider({ children }: PropsWithChildren) {
  const [providers, setProviders] = useState<Array<FC<any>>>([]);

  const addProviders = (providers: Array<FC<any>>) => {
    setProviders((existingProviders) => {
      const uniqueProviders = providers.filter(
        (provider) => !existingProviders.some((p) => p === provider)
      );
      return [...existingProviders, ...uniqueProviders];
    });
  };

  return (
    <ContextProvidersContext.Provider
      value={{
        providers,
        addProviders
      }}
    >
      {children}
    </ContextProvidersContext.Provider>
  );
}

export function useContextProviders(...providers: Array<FC<any>>) {
  const { providers: existingProviders, addProviders } = useContext(
    ContextProvidersContext
  );

  useEffect(() => {
    if (providers.length) {
      addProviders(providers);
    }
  }, []);

  return existingProviders;
}
