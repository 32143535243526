import { useNotifications } from './Notifications.hooks';
import { NotificationsView } from './NotificationsView';

export const Notifications = () => {
  const { reportingModuleNotifications, isLoading } = useNotifications();

  return (
    <NotificationsView
      reportingModuleNotifications={reportingModuleNotifications}
      isLoading={isLoading}
    />
  );
};
