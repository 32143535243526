import pckg from '../../../../../package.json';

/*
 * getVersion()
 * return the latest software version retrieved from the pipeline or inside package.json
 */
const getVersion = () => {
  const version = localStorage.getItem('version');
  if (!version) {
    return `${process.env.NX_REACT_APP_BUILD_ID || pckg.version}`;
  }
  return version;
};

export const VERSION = getVersion();
