import { DistributionReportAttachmentType } from '@partnerconnect-monorepo/types';
import { t } from '@transifex/native';

export const AttachmentTypeOptions = [
  {
    value: DistributionReportAttachmentType.Waybill,
    label: t('Waybill')
  },
  {
    value: DistributionReportAttachmentType.NutritionKpisReports,
    label: t('Nutrition KPIs reports')
  },
  {
    value: DistributionReportAttachmentType.NarrativeReports,
    label: t('Narrative reports')
  },
  {
    value: DistributionReportAttachmentType.LossReports,
    label: t('Loss reports')
  }
];
