import { T } from '@transifex/react';
import { Icon, Story, Wrapper } from '@wfp/ui';
import { iconWfpHumEmergencyResponsePos } from '@wfp/icons';
import './styles.scss';

export const NotFound = () => (
  <Wrapper pageWidth="lg" spacing="md">
    <Story className="wfp--story__center wfp--story__full-height">
      <Icon
        description="Not found"
        icon={iconWfpHumEmergencyResponsePos}
        className="notFoundIcon"
        fill="#0a6eb4"
        width="200"
        height="200"
        style={{ marginBottom: '3rem', transform: 'rotate(10deg)' }}
      />
      <h1 className="wfp--story__title">
        <T _str="Page not found" />
      </h1>
      <p>
        <T _str="Go back to" />{' '}
        <a href="/">
          <T _str="Homepage" />
        </a>
      </p>
    </Story>
  </Wrapper>
);
