import { t } from '@transifex/native';

export const DEFAULT_PAGE_SIZE = 10;

export const FIRST_PAGE = 1;

export const defaultPaginationProps = {
  itemRangeText: (min: number, max: number, total: number) =>
    t('{min}-{max} of {total} items', { min, max, total }),
  itemsPerPageText: t('Items per page:'),
  pageRangeText: (current: number, total: number) =>
    t('{current} of {total} pages', { current, total }),
  pageSizes: [DEFAULT_PAGE_SIZE, 20, 30, 50]
};

export const initialPaginationParams = {
  page: FIRST_PAGE,
  pageSize: DEFAULT_PAGE_SIZE
};
