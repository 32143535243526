import { Icon, Text } from '@wfp/ui';
import { iconWfpHumEmergencyResponsePos } from '@wfp/icons';
import { t } from '@transifex/native';
import './NoResultsFound.scss';
import { T } from '@transifex/react';

export const NoResultsFound = ({ title }: { title: string }) => {
  return (
    <div className="results-not-found-wrapper">
      <Icon
        icon={iconWfpHumEmergencyResponsePos}
        description={t('No results found icon')}
        className="results-not-found-icon"
        fill="#0a6eb4"
        width="100"
        height="100"
        style={{ transform: 'rotate(10deg)' }}
      />

      <Text className="results-not-found-title">
        <T _str={title} />
      </Text>
    </div>
  );
};
