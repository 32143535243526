import { NotificationInterface } from '@partnerconnect-monorepo/types';
import { useNotification } from './Notification.hooks';
import { NotificationView } from './NotificationView';

interface NotificationProps {
  notification: NotificationInterface;
  module: 'reporting' | 'partnershipsAndFinances';
}

export const Notification = ({ notification, module }: NotificationProps) => {
  const { control, isLoading } = useNotification(notification, module);

  return (
    <NotificationView
      control={control}
      frequencyOptions={notification.allowedFrequencies}
      isLoading={isLoading}
    />
  );
};
