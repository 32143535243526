import { PropsWithChildren } from 'react';
import { useContextProviders } from '../context/contextProviders';

export function ComposeContexts({ children }: PropsWithChildren) {
  const providers = useContextProviders();

  return (
    <>
      {providers.reduceRight((acc, Comp) => {
        return <Comp>{acc}</Comp>;
      }, children)}
    </>
  );
}
