import { PropsWithChildren } from 'react';
import EnvironmentBanner from '../components/common/EnvironmentBanner';
import { Navbar } from '../components/common/Navbar';
import Footer from '../components/common/Footer';
import { Announcements } from '../components';
import { useAuth } from 'react-oidc-context';
import './Layout.scss';

export const FullLayout = ({ children }: PropsWithChildren) => {
  const { isAuthenticated } = useAuth();

  return (
    <>
      <EnvironmentBanner />
      <div className="app__content-box">
        <Navbar />
        {isAuthenticated && <Announcements />}
        {children}
      </div>
      <Footer />
    </>
  );
};
