import { NotificationInterface } from '@partnerconnect-monorepo/types';
import { T } from '@transifex/react';
import { Loading, Text } from '@wfp/ui';
import './Notifications.scss';
import { Notification } from './Notification';

interface NotificationsViewProps {
  reportingModuleNotifications: Array<NotificationInterface> | undefined;
  isLoading: boolean;
}

export const NotificationsView = ({
  reportingModuleNotifications,
  isLoading
}: NotificationsViewProps) => (
  <>
    <Text kind="h4">
      <T _str="Notifications" />
    </Text>
    {isLoading ? (
      <Loading withOverlay={false} small />
    ) : !reportingModuleNotifications?.length ? (
      <Text kind="p">
        <T _str="No notifications to configure" />
      </Text>
    ) : (
      !!reportingModuleNotifications?.length && (
        <>
          <Text kind="h5" className="mb-1">
            <T _str="Reporting" />
          </Text>
          <div className="notifications">
            {reportingModuleNotifications?.map(
              (notification, notificationIdx) => (
                <Notification
                  key={`reporting-${notificationIdx}`}
                  notification={notification}
                  module="reporting"
                />
              )
            )}
          </div>
        </>
      )
    )}
  </>
);
