import { NotificationInterface } from '@partnerconnect-monorepo/types';
import { t } from '@transifex/native';
import { useUpdateNotification } from '../.././../../api';
import { useSnack } from '../../../../context';
import { isEqual } from 'lodash';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

export const useNotification = (
  notification: NotificationInterface,
  module: 'reporting' | 'partnershipsAndFinances'
) => {
  const { handleSubmit, watch, control, reset } =
    useForm<NotificationInterface>({
      defaultValues: notification
    });

  const snack = useSnack();

  const { mutate: updateNotification, isLoading: isUpdateNotificationLoading } =
    useUpdateNotification();

  const onSubmit = ({ code, enabled, frequency }: NotificationInterface) =>
    updateNotification(
      {
        code,
        enabled,
        module,
        frequency: frequency.value
      },
      {
        onSuccess: () =>
          snack.success(t('Notification settings updated successfully')),
        onError: () => {
          snack.error(t('Updating notification settings failed'));
          reset(notification);
        }
      }
    );

  // auto-save
  useEffect(() => {
    const subscription = watch(
      (updatedNotification) =>
        !isEqual(updatedNotification, notification) && handleSubmit(onSubmit)()
    );

    return () => subscription.unsubscribe();
  }, [handleSubmit, watch]);

  return {
    control,
    isLoading: isUpdateNotificationLoading
  };
};
