import type { PropsWithChildren } from 'react';
import { useState } from 'react';
import { Blockquote, Icon, Wrapper, Button } from '@wfp/ui';
import { iconClose } from '@wfp/icons';
import type { InfoBanner } from '@partnerconnect-monorepo/types';

export const DISMISS_ANNOUNCEMENT_BUTTON_TEST_ID =
  'dismiss-announcement-button';

export const ONE_TIME_VIEW = 'oneTimeView';

const getOneTimeViewList = () => {
  const oneTimeViewItem: string | null = localStorage.getItem(ONE_TIME_VIEW);

  const oneTimeViewList: Array<string> = oneTimeViewItem
    ? JSON.parse(oneTimeViewItem)
    : [];

  return oneTimeViewList;
};

export const Announcement = ({
  children,
  urlConfig,
  dismissible,
  withIcon,
  title,
  bannerType,
  oneTimeView,
  id
}: PropsWithChildren<InfoBanner>) => {
  const [isDismissed, setIsDismissed] = useState(false);

  const oneTimeViewList = getOneTimeViewList();

  const isMatchOneTimeViewList = oneTimeViewList.some(
    (item: string) => item === id
  );

  const pathname = window.location.pathname;

  const isVisible =
    !isDismissed &&
    urlConfig?.some((item) => {
      return item.exact ? item.url === pathname : pathname.includes(item.url);
    });

  const handleClose = () => {
    const oneTimeViewList = getOneTimeViewList();

    if (oneTimeView) {
      localStorage.setItem(
        ONE_TIME_VIEW,
        JSON.stringify([...oneTimeViewList, id])
      );
    }

    setIsDismissed(true);
  };

  if (!isVisible || isMatchOneTimeViewList) return null;

  return (
    <Wrapper
      pageWidth="lg"
      background="lighter"
      className="announcements-wrapper"
    >
      <Blockquote kind={bannerType} withIcon={withIcon} title={title}>
        {children}
        {dismissible && (
          <Button
            className="blockquote-announcement__close"
            type="button"
            onClick={handleClose}
            kind="ghost"
            small
            data-testid={DISMISS_ANNOUNCEMENT_BUTTON_TEST_ID}
          >
            <Icon icon={iconClose} description="dismiss" />
          </Button>
        )}
      </Blockquote>
    </Wrapper>
  );
};
