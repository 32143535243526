// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import axios from 'axios';
import { PropsWithChildren, useCallback, useLayoutEffect } from 'react';
import { useAuth } from 'react-oidc-context';
import { useEnv } from '../context';
import { SIGN_IN_CALLBACK_RETURN_URL } from '../constants';
import { Loading } from '@wfp/ui';

const instance = axios.create();

const AxiosInterceptor = ({ children }: PropsWithChildren) => {
  const { baseUrl } = useEnv();

  const { user, removeUser, isLoading, error, isAuthenticated } = useAuth();

  const removeUserAndRedirectToHomepage = useCallback(
    (error?: PromiseRejectedResult | Error) => {
      removeUser();
      !window.location.href.includes(SIGN_IN_CALLBACK_RETURN_URL) &&
        window.location.replace(
          `/?${SIGN_IN_CALLBACK_RETURN_URL}=${window.location.href}`
        );
      return Promise.reject(error);
    },
    [removeUser]
  );

  const isAccessingPublicPage = ['/', '/support'].includes(
    window.location.pathname
  );

  useLayoutEffect(() => {
    if ((error || !isAuthenticated) && !isLoading && !isAccessingPublicPage) {
      removeUserAndRedirectToHomepage(error);
      return;
    } else if (isLoading && !user) return;

    const requestInterceptor = instance.interceptors.request.use(
      (config) => {
        config.baseURL = baseUrl;
        if (config.headers && user?.id_token) {
          config.headers['authorization'] = `Bearer ${user.id_token}`;
        }
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    const responseInterceptor = instance.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response || user?.expired) {
          if (error.response?.status === 401) {
            if (error.response?.data?.code === 'unknown_user') {
              return Promise.reject();
            }
            return removeUserAndRedirectToHomepage(error);
          }
          if (
            error.response?.status === 400 &&
            error.response?.data?.error === 'invalid_grant'
          ) {
            return removeUserAndRedirectToHomepage(error);
          }
        }
        return Promise.reject(error);
      }
    );

    return () => {
      instance.interceptors.request.eject(requestInterceptor);
      instance.interceptors.response.eject(responseInterceptor);
    };
  }, [
    baseUrl,
    user,
    isLoading,
    removeUserAndRedirectToHomepage,
    error,
    isAccessingPublicPage,
    isAuthenticated
  ]);

  return !isLoading && (isAuthenticated || isAccessingPublicPage) ? (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>{children}</>
  ) : (
    <Loading />
  );
};

export { AxiosInterceptor, instance as axios };
