import { T } from '@transifex/react';
import {
  Item,
  Link,
  Module,
  SecondaryNavigation,
  SecondaryNavigationTitle,
  Sidebar,
  SidebarScroll,
  Text,
  Wrapper
} from '@wfp/ui';
import './Preferences.scss';
import { PreferenceTab } from '../../constants';
import { Notifications } from './Notifications';

interface PreferencesViewProps {
  activeItem: number;
  setActiveItem: (itemIdx: number) => void;
}

export const PreferencesView = ({
  activeItem,
  setActiveItem
}: PreferencesViewProps) => (
  <>
    <SecondaryNavigation>
      <SecondaryNavigationTitle>
        <T _str="Preferences" />
      </SecondaryNavigationTitle>
    </SecondaryNavigation>
    <Wrapper pageWidth="lg" mobilePageWidth="full">
      <Module className="module" id="preferences">
        <Sidebar
          className="preferences-sidebar"
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          active={activeItem}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          sidebar={
            <SidebarScroll>
              <Item
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                kind="horizontal"
                title={<T _str="Personal information" />}
                wrapper="sidebar"
                active={activeItem === PreferenceTab.PersonalInfo}
                onClick={() => setActiveItem(PreferenceTab.PersonalInfo)}
              />
              <Item
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                kind="horizontal"
                title={<T _str="Notifications" />}
                wrapper="sidebar"
                active={activeItem === PreferenceTab.Notifications}
                onClick={() => setActiveItem(PreferenceTab.Notifications)}
              />
            </SidebarScroll>
          }
        >
          <div className="preferences-sidebar-item-content">
            {activeItem === PreferenceTab.PersonalInfo ? (
              <>
                <Text kind="h4" className="mb-1">
                  <T _str="Personal information" />
                </Text>
                <Link
                  href="https://ciam.auth.wfp.org/myaccount/personal-info"
                  target="_blank"
                >
                  <T _str="Update CIAM account" />
                </Link>
              </>
            ) : (
              activeItem === PreferenceTab.Notifications && <Notifications />
            )}
          </div>
        </Sidebar>
      </Module>
    </Wrapper>
  </>
);
