import { OptionElement } from '../types';

export enum DistributionReportAttachmentFilterType {
  Monthly = 'monthly',
  AdHoc = 'adhoc'
}

export enum DistributionReportAttachmentType {
  Waybill = 'waybill',
  NutritionKpisReports = 'nutrition_kpis_reports',
  NarrativeReports = 'narrative_reports',
  LossReports = 'loss_reports',
  Other = 'other'
}

interface FormikDistributionReportAttachmentTypeOptionElement
  extends OptionElement {
  __isNew__?: boolean;
}

export interface FormikDistributionReportAttachment {
  type?: FormikDistributionReportAttachmentTypeOptionElement;
  typeOther?: string;
  file: File | string;
  filename: string;
  id: string;
  uploadedBy: string;
}
