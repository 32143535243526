const second = 1000;
const minute = 60 * second;

// Determines how much time cached response lasts
export enum StaleTimes {
  Second5 = 5 * second,
  Second10 = 10 * second,
  Second30 = 30 * second,
  Minute1 = minute,
  Minute3 = 3 * minute,
  Minute5 = 5 * minute,
  Infinite = Infinity
}
