import { PropsWithChildren } from 'react';
import { AccordionItem } from './AccordionItem';
import './Accordion.scss';

const Accordion = ({ children }: PropsWithChildren) => (
  <ul className="accordion">{children}</ul>
);

Accordion.Item = AccordionItem;

export { Accordion };
