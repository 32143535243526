import { useLanguages } from '@transifex/react';
import { Language } from '@partnerconnect-monorepo/types';
import { useEffect, useMemo } from 'react';
import { useEnv } from '../context';
import { txInstance } from '../transifex';
import { DEFAULT_LOCALE } from '../constants';

const CURRENT_LOCALE_LOCAL_STORAGE_ITEM_NAME = 'currentLocale';
const LEFT_TO_RIGHT_DIRECTIONALITY = 'ltr';
const RIGHT_TO_LEFT_DIRECTIONALITY = 'rtl';
const TRANSIFEX_LOCALE_SEPARATOR = '_';
const LOCALE_SEPARATOR = '-';

export const useCurrentLocale = () => {
  const env = useEnv();

  const languages = useLanguages() as Array<Language>;

  const currentLanguage = useMemo(() => {
    const currentLanguage = localStorage.getItem(
      CURRENT_LOCALE_LOCAL_STORAGE_ITEM_NAME
    );
    return currentLanguage
      ? (JSON.parse(currentLanguage) as Language)
      : undefined;
  }, []);

  useEffect(() => {
    if (env?.transifexToken) {
      txInstance.setCurrentLocale(currentLanguage?.code ?? DEFAULT_LOCALE);
    }
    document.documentElement.dir = currentLanguage?.rtl
      ? RIGHT_TO_LEFT_DIRECTIONALITY
      : LEFT_TO_RIGHT_DIRECTIONALITY;
  }, [currentLanguage?.code, currentLanguage?.rtl, env]);

  const setLocale = (languageCode: string) => {
    localStorage.setItem(
      CURRENT_LOCALE_LOCAL_STORAGE_ITEM_NAME,
      JSON.stringify(languages.find(({ code }) => code === languageCode))
    );

    /**
     * reload the page to:
     * - use the selected locale for Moment and Date object values localization,
     * - rerender AG Grid component(s) to adapt to language's directionality
     */
    window.location.reload();
  };

  const { supportedLanguages } = useEnv();

  return {
    locale:
      currentLanguage?.code.replace(
        TRANSIFEX_LOCALE_SEPARATOR,
        LOCALE_SEPARATOR
      ) ?? DEFAULT_LOCALE,
    isRTL: currentLanguage?.rtl,
    languages: languages.filter(({ code }) =>
      (supportedLanguages ?? '').includes(code)
    ),
    setLocale
  };
};
