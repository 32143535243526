import { InfoBanner } from '@partnerconnect-monorepo/types';
import { axios, mapToCamelCase } from '../../index';
import { useQuery } from 'react-query';

const getPartnershipsInfoBanner = () =>
  axios
    .get(`${process.env.NX_FLA_APP_BE_BASE}/profile/infobanner`)
    .then<Array<InfoBanner>>((res) => mapToCamelCase(res.data));

export const usePartnershipsInfoBanner = () =>
  useQuery(['PARTNERSHIPS_INFOBANNER'], getPartnershipsInfoBanner);
