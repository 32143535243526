import { hasPermission } from '../../helpers';
import { useUserProfile } from '../../context';
import { PropsWithChildren, useMemo } from 'react';
import { FeatureFlag, NO_ACCESS_PATH } from '../../constants';
import { useFeature } from 'flagged';

export const PrivateRoute = ({
  children,
  permissionCheck
}: PropsWithChildren<{ permissionCheck: string | null }>) => {
  const { userProfile } = useUserProfile();

  const canAccessReporting = useMemo(
    () =>
      window.location.pathname.includes('reporting') &&
      userProfile?.allowedModules?.canAccessReporting,
    [userProfile?.allowedModules?.canAccessReporting]
  );

  const canAccessPartnerships = useMemo(
    () =>
      window.location.pathname.includes('partnerships') &&
      userProfile?.allowedModules?.canAccessPartnerships,
    [userProfile?.allowedModules?.canAccessPartnerships]
  );

  const canAccessInvoicing = window.location.pathname.includes('invoicing');

  const isSpotCheckModuleEnabled = useFeature(
    FeatureFlag.SpotChecksModule
  ) as boolean;

  const canAccessSpotChecks =
    window.location.pathname.includes('spot-checks') &&
    isSpotCheckModuleEnabled;

  const isAccessingOtherPathname = useMemo(
    () =>
      ['reporting', 'partnerships', 'invoicing', 'spot-checks'].every(
        (pathname) => !window.location.pathname.includes(pathname)
      ),
    []
  );

  const canAccessProtectedModule =
    canAccessReporting ||
    canAccessPartnerships ||
    canAccessInvoicing ||
    canAccessSpotChecks;

  const canAccess = useMemo(
    () =>
      userProfile &&
      (canAccessProtectedModule || isAccessingOtherPathname) &&
      permissionCheck
        ? hasPermission(userProfile?.permissions, permissionCheck)
        : false,
    [
      canAccessProtectedModule,
      isAccessingOtherPathname,
      permissionCheck,
      userProfile
    ]
  );

  if (!canAccess) window.location.replace(NO_ACCESS_PATH);
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return canAccess ? <>{children}</> : null;
};
