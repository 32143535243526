import { PropsWithChildren } from 'react';
import './Dot.scss';

interface DotProps extends PropsWithChildren {
  color: 'yellow' | 'green';
}

export const Dot = ({ children, color }: DotProps) => (
  <div className="dot-wrapper">
    <span className={`dot ${color}`} />
    <span className="dot-label">{children}</span>
  </div>
);
