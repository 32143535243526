import { OpportunityDocType } from '@partnerconnect-monorepo/types';

export function getFileNameFromUrl(url?: string) {
  if (!url) {
    return '';
  }

  const splitted = url.split('/');
  const lastElement = splitted[splitted.length - 1];
  const finalName = lastElement?.split('?')[0];
  return finalName || '';
}

export const getOpportunityDocFilename = (
  documentType: OpportunityDocType,
  flaNumber: string,
  documentExtension: string,
  documentVersion: number
) => {
  let filenamePrefix, fileExtension;
  switch (documentType) {
    case OpportunityDocType.Budget:
      filenamePrefix = 'FLA-budget';
      fileExtension = documentExtension || 'xlsx';
      break;
    case OpportunityDocType.Proposal:
      filenamePrefix = 'FLA-project-proposal';
      fileExtension = documentExtension || 'pdf';
      break;
    case OpportunityDocType.NFR:
      filenamePrefix = 'FLA-CPC-NFR';
      fileExtension = documentExtension || 'pdf';
      break;
    case OpportunityDocType.FLA:
      filenamePrefix = 'FLA';
      fileExtension = documentExtension || 'pdf';
  }

  return `${filenamePrefix}-${flaNumber.replaceAll(
    '/',
    '-'
  )}-V${documentVersion}.${fileExtension}`;
};

export const getAdditionalDocFilename = (
  name: string,
  fileExtension: string
) => {
  return `${name}.${fileExtension}`;
};
