import { t } from '@transifex/native';

export enum MonthlyReportStatus {
  ReadyForSubmission = 'ready_for_submission',
  Draft = 'draft',
  AwaitingWfpReview = 'awaiting_wfp_review',
  AwaitingWfpVerification = 'awaiting_wfp_verification',
  Awaiting2ndWfpVerification = 'awaiting_2nd_wfp_verification',
  ReadyForReview = 'ready_for_review',
  AwaitingPartnerApproval = 'awaiting_partner_approval',
  AwaitingWfpApproval = 'awaiting_wfp_approval',
  ApprovedAwaitingSync = 'approved_awaiting_sync',
  Approved = 'approved',
  Error = 'error'
}

export const MONTHLY_REPORT_STATUS: { [key: string]: string } = {
  [MonthlyReportStatus.ReadyForSubmission]: t('Ready for submission'),
  [MonthlyReportStatus.Draft]: t('Draft'),
  [MonthlyReportStatus.AwaitingWfpReview]: t('Awaiting WFP review'),
  [MonthlyReportStatus.ReadyForReview]: t('Ready for review'),
  [MonthlyReportStatus.AwaitingPartnerApproval]: t('Awaiting partner approval'),
  [MonthlyReportStatus.AwaitingWfpApproval]: t('Awaiting WFP approval'),
  [MonthlyReportStatus.ApprovedAwaitingSync]: t('Validation pending'),
  [MonthlyReportStatus.Approved]: t('Approved'),
  [MonthlyReportStatus.Error]: t('Approved (sync error)'),
  [MonthlyReportStatus.AwaitingWfpVerification]: t('Awaiting WFP verification'),
  [MonthlyReportStatus.Awaiting2ndWfpVerification]: t(
    'Awaiting 2nd WFP verification'
  )
};

export enum MonthlyReportCometStatus {
  Template = 'template',
  Draft = 'draft',
  Submitted = 'submitted',
  Validated = 'validated'
}
