import { axios, mapToCamelCase } from '../../index';
import { useQuery } from 'react-query';
import { APIHelpSection } from '@partnerconnect-monorepo/types';

const getHelpSections = () =>
  axios
    .get(`/help-sections/`)
    .then<Array<APIHelpSection>>((res) => mapToCamelCase(res.data));

export const useHelpSections = () =>
  useQuery(['HELP_SECTIONS'], getHelpSections);
