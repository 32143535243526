export interface History {
  historyId: number;
  historyUser?: {
    fullName: string;
    email: string;
  };
  historyType: string;
  historyDate: string;
  historyChangeReason?: string;
}

export enum HistoryChangeReason {
  Reset = 'reset',
  SubmitToPartnerApproval = 'submit_to_partner_approval',
  VerifyAsWfpToAwaitWfpApprove = 'verify_as_wfp_to_await_wfp_approve',
  VerifyAsWfpTo2ndVerification = 'verify_as_wfp_to_2nd_verification',
  VerifyAs2ndWfpVerifierToAwaitWfpApprove = 'verify_as_2nd_wfp_verifier_to_await_wfp_approve',
  ApproveAsPartnerToAwaitWfpVerification = 'approve_as_partner_to_await_wfp_verification',
  ApproveAsPartnerToAwaitWfpApprove = 'approve_as_partner_to_await_wfp_approve',
  ApproveAsWfpAfterPartnerApproval = 'approve_as_wfp_after_partner_approval',
  Error = 'error',
  SaveAsDraft = 'save_as_draft',
  SendBackAsPartnerToDraft = 'send_back_as_partner_to_draft',
  SendBackAsWfpToDraft = 'send_back_as_wfp_to_draft',
  SendBackAsWfpToWfpAppover = 'send_back_as_wfp_to_wfp_appover',
  SendBackAsWfpVerifier1ToDraft = 'send_back_as_wfp_verifier_to_draft',
  SendBackAsWfpVerifier2ToDraft = 'send_back_as_2nd_wfp_verifier_to_draft',
  ResetDraft = 'reset_draft',
  CometDraft = 'comet_draft',
  CometSubmit = 'comet_submit',
  CometValidate = 'comet_validate'
}

export const COMET_HISTORY_CHANGE_REASONS: Array<string> = [
  HistoryChangeReason.CometDraft,
  HistoryChangeReason.CometSubmit,
  HistoryChangeReason.CometValidate
];
