import { NotificationFrequency } from '@partnerconnect-monorepo/types';
import { t } from '@transifex/native';

export const NotificationFrequencyLabel: Record<NotificationFrequency, string> =
  {
    trigger: t('Trigger'),
    weekly: t('Weekly summary')
  };

export enum NotificationCode {
  ReportReadyForWfpApproval = 'report_ready_for_wfp_approval',
  ReportReadyForPartnerApproval = 'report_ready_for_partner_approval',
  AdHocDeleted = 'ad_hoc_deleted',
  ReportApproved = 'report_approved',
  NewMonthReportReminder = 'new_month_report_reminder',
  ReportSentBackByWfp = 'report_sent_back_by_wfp',
  ReportSentBackByPartner = 'report_sent_back_by_partner',
  ReportReadyForWfpVerification = 'report_ready_for_wfp_verification'
}

export const NOTIFICATION_LABEL: Record<string, string> = {
  [NotificationCode.ReportReadyForWfpApproval]: t(
    'Monthly distribution report is ready for approval'
  ),
  [NotificationCode.ReportReadyForPartnerApproval]: t(
    'Monthly distribution report has been submitted by partner editor'
  ),
  [NotificationCode.AdHocDeleted]: t(
    'Daily submission has been deleted by editor'
  ),
  [NotificationCode.ReportApproved]: t(
    'Monthly distribution report has been approved'
  ),
  [NotificationCode.NewMonthReportReminder]: t(
    'Monthly distribution report is ready for submission'
  ),
  [NotificationCode.ReportSentBackByWfp]: t(
    'Monthly distribution report has been sent back by WFP approver'
  ),
  [NotificationCode.ReportSentBackByPartner]: t(
    'Monthly distribution report has been sent back by partner approver'
  ),
  [NotificationCode.ReportReadyForWfpVerification]: t(
    'Monthly distribution report has been submitted by partner approver'
  )
};
