import {
  useState,
  useEffect,
  useContext,
  createContext,
  ReactNode
} from 'react';

interface OnlineStatusContextProps {
  onlineStatus: boolean;
}

type OnlineStatusProps = {
  children: ReactNode;
};

const OnlineStatusContext = createContext<OnlineStatusContextProps | undefined>(
  undefined
);

export const OnlineStatusProvider = ({ children }: OnlineStatusProps) => {
  const [onlineStatus, setOnlineStatus] = useState<boolean>(false);

  useEffect(() => {
    if (navigator.onLine) {
      setOnlineStatus(true);
    }
    window.addEventListener('offline', () => {
      setOnlineStatus(false);
    });
    window.addEventListener('online', () => {
      setOnlineStatus(true);
    });

    return () => {
      window.removeEventListener('offline', () => {
        setOnlineStatus(false);
      });
      window.removeEventListener('online', () => {
        setOnlineStatus(true);
      });
    };
  }, []);

  return (
    <OnlineStatusContext.Provider value={{ onlineStatus }}>
      {children}
    </OnlineStatusContext.Provider>
  );
};

export const useOnlineStatus = () => {
  const context = useContext(OnlineStatusContext);
  if (context === undefined) {
    throw new Error(
      'useOnlineStatus must be used within a OnlineStatusProvider'
    );
  }
  return context;
};
