import { OptionElement } from '@partnerconnect-monorepo/types';
import { FlaUserRoleLabel, ReportingUserRoleLabel } from '../constants';
import { t } from '@transifex/native';

export const getRolesLabel = (roles: Array<OptionElement> | undefined) =>
  roles?.map(({ label }) => t(label)).join('/');

export const getRolesValue = (roles: Array<OptionElement> | undefined) =>
  roles?.map(({ value }) => t(value)).join('/');

export const getReportingUserRoleOptions = (
  roles: Array<OptionElement> | undefined
) =>
  roles?.map(({ value }) => ({
    value,
    label: ReportingUserRoleLabel[value]
  })) ?? [];

export const getFlaUserRoleOptions = (
  roles: Array<OptionElement> | undefined
) =>
  roles?.map(({ value }) => ({ value, label: FlaUserRoleLabel[value] })) ?? [];
