import { Footer as WFPFooter, Link } from '@wfp/ui';
import { T } from '@transifex/react';
import { getCurrentYear } from '../../../helpers';
import { VERSION } from '../../../utils/version';
import { useMemo } from 'react';

export const Footer = () => {
  const currentYear = useMemo(() => getCurrentYear(), []);

  return (
    <WFPFooter
      external
      metaContent={`${currentYear} © World Food Programme`}
      pageWidth="lg"
    >
      <div data-testid="footer-wfp-info">
        <T
          _str="The United Nations World Food Programme - saving lives in emergencies and changing lives for millions through sustainable development. WFP works in more than 80 countries around the world, feeding people caught in conflict and disasters, and laying the foundations for a better future."
          _tag="footer"
        />
        <hr />
        <ul className="wfp--footer__list" data-testid="footer-wfp-list">
          <li>
            <Link
              href="http://www.wfp.org/privacy-policy"
              target="_blank"
              data-testid="footer-privacy"
            >
              <T _str="Privacy" _tag="footer" />
            </Link>{' '}
            |{' '}
            <Link
              href="https://cdn.wfp.org/legal/terms/"
              target="_blank"
              data-testid="footer-terms-of-use"
            >
              <T _str="Terms of use" _tag="footer" />
            </Link>{' '}
            |{' '}
            <Link href="https://www.wfp.org" data-testid="footer-wfp-org">
              WFP.org
            </Link>
          </li>
        </ul>
        <small data-testid="footer-version">ver. {VERSION}</small>
      </div>
    </WFPFooter>
  );
};
