import { SupportView } from './SupportView';
import { useSupport } from './Support.hooks';
import { Loading } from '@wfp/ui';

export const Support = () => {
  const { activeItem, setActiveItem, isHelpSectionsLoading, helpSections } =
    useSupport();

  if (isHelpSectionsLoading) {
    return <Loading />;
  }

  return (
    <SupportView
      activeItem={activeItem}
      helpSections={helpSections}
      setActiveItem={setActiveItem}
    />
  );
};
