export enum Applications {
  Fla = 'Partnerships',
  Reporting = 'Reporting',
  Home = 'Home',
  Invoicing = 'Invoicing',
  Spotcheck = 'Spotcheck'
}

export enum Enviroment {
  LOCAL = 'local',
  DEV = 'dev',
  QA = 'qa',
  PRODUCTION = 'production'
}

export interface EnvironmentVariables {
  production: boolean;
  appName: Applications;
  baseUrl: string;
  publicUrl: string;
  environment: string;
  authUrl: string;
  logoutUrl: string;
  authenticationClientId: string;
  authRedirectUrl: string;
  authLogoutRedirectUrl: string;
  transifexToken: string;
  sentryDsn: string;
  reactAppBaseRoute: string;
  deployedOnRootPath?: boolean;
  enableFlaAmendment: boolean;
  supportedLanguages: string;
  enableReportingOverviewPage: boolean;
  enablePreferencesPage: boolean;
  enableNfrDigitalSigning: boolean;
  enableSpotcheckModule: boolean;
}
