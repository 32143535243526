import { Announcement } from './components/Announcement';
import type { InfoBanner } from '@partnerconnect-monorepo/types';
import './Announcements.scss';

interface AnnouncementsViewProps {
  banners: Array<InfoBanner>;
}

export const AnnouncementsView = ({ banners }: AnnouncementsViewProps) => {
  return (
    <>
      {banners.map((item, index) => (
        <Announcement
          key={index}
          withIcon={item.withIcon}
          title={item.title}
          bannerType={item.bannerType}
          urlConfig={item.urlConfig}
          visibleFor={item.visibleFor}
          dismissible={item.dismissible}
          oneTimeView={item.oneTimeView}
          content={item.content}
          id={item.id}
        >
          {item.content && (
            <div dangerouslySetInnerHTML={{ __html: item.content }}></div>
          )}
        </Announcement>
      ))}
    </>
  );
};
