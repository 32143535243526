import {
  useReportingInfoBanner,
  usePartnershipsInfoBanner
} from '../../../services';
import dompurify from 'dompurify';

export const useAnnouncements = () => {
  const { data: reportingInfoBanners = [] } = useReportingInfoBanner();

  const { data: partnershipsInfoBanners = [] } = usePartnershipsInfoBanner();

  const banners = [...reportingInfoBanners, ...partnershipsInfoBanners].map(
    ({ content, ...otherProps }) => ({
      ...(content && {
        content: dompurify.sanitize(content)
      }),
      ...otherProps
    })
  );

  return {
    banners
  };
};
