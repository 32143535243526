import { Button, InlineLoading } from '@wfp/ui';
import { iconDownload } from '@wfp/icons';
import './DownloadFileButton.scss';

interface DownloadFileButtonViewProps {
  downloadFile: () => void;
  filename: string;
  downloadedFileUrl: string;
  isDownloading: boolean;
  linkRef: React.MutableRefObject<HTMLAnchorElement | null>;
  label: string;
  withIcon: boolean;
  small: boolean;
  reverseOrder: boolean;
}

export const DownloadFileButtonView = ({
  filename,
  downloadFile,
  isDownloading,
  downloadedFileUrl,
  linkRef,
  label,
  withIcon,
  small,
  reverseOrder
}: DownloadFileButtonViewProps) => (
  <div
    className={`download-file-button__wrapper ${
      reverseOrder ? 'reverse-order' : ''
    }`}
  >
    <a
      href={downloadedFileUrl}
      download={filename}
      className="download-file-button__hidden-link"
      ref={linkRef}
    >
      {label}
    </a>
    <Button
      kind="secondary"
      icon={withIcon && iconDownload}
      iconDescription="file download icon"
      onClick={downloadFile}
      small={small}
      className="download-file-button__button"
    >
      <span className="download-file-button__button-label">{label}</span>
    </Button>
    <InlineLoading className={!isDownloading ? 'hidden' : ''} />
  </div>
);
