import { OptionElement } from '@partnerconnect-monorepo/types';
import ReactSelect, { MultiValue, Props } from 'react-select';
import {
  formatOptionLabel,
  shouldToggleAllOptionElementsSelected,
  withSelectAllOptionElement
} from './utils';
import './MultiSelectWithAll.scss';

interface MultiSelectWithAllProps
  extends Omit<Props<OptionElement>, 'onChange' | 'options'> {
  onChange: (multiValue: MultiValue<OptionElement>) => void;
  options: Array<OptionElement>;
}

export const MultiSelectWithAll = (props: MultiSelectWithAllProps) => (
  <ReactSelect
    {...props}
    className="wfp--react-select-container multi-select-with-all"
    classNamePrefix="wfp--react-select"
    onChange={(multiValue) =>
      props.onChange(
        shouldToggleAllOptionElementsSelected(
          multiValue as MultiValue<OptionElement>
        )
          ? (props.value as MultiValue<OptionElement>).length ===
            props.options?.length
            ? []
            : props.options
          : (multiValue as MultiValue<OptionElement>)
      )
    }
    options={
      props.options
        ? withSelectAllOptionElement(
            props.options,
            ((props.value ?? []) as MultiValue<OptionElement>).length ===
              props.options.length
          )
        : []
    }
    formatOptionLabel={formatOptionLabel}
    isMulti
    {...(props.isDisabled && {
      components: { MultiValueRemove: () => null }
    })}
  />
);
