import { createContext, PropsWithChildren, useContext } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import { NotificationKind } from '@wfp/ui/src/types/utils';
import {
  SnackbarContextBaseInterface,
  SnackbarContextInterface,
  SnackbarProps,
  SnackbarPropsBase
} from '@partnerconnect-monorepo/types';
import { Toaster } from '../components/common/Toaster';

export const SnackBarContext = createContext<
  SnackbarContextInterface | undefined
>(undefined);

export function SnackbarContextProvider({
  children,
  limit,
  position,
  hideProgressBar,
  autoClose
}: PropsWithChildren<SnackbarContextBaseInterface>) {
  const displaySnackbar = (props: SnackbarProps) => {
    toast(() => <Toaster {...props} />, {
      position: props.position
    });
  };
  const displayConcreteSnack =
    (kind: NotificationKind) => (propsOrTitle: SnackbarPropsBase | string) => {
      if (typeof propsOrTitle === 'string') {
        displaySnackbar({ title: propsOrTitle, kind });
      } else {
        displaySnackbar({ ...propsOrTitle, kind });
      }
    };

  return (
    <SnackBarContext.Provider
      value={{
        snack: displaySnackbar,
        success: displayConcreteSnack('success'),
        error: displayConcreteSnack('error'),
        warning: displayConcreteSnack('warning'),
        warningAlt: displayConcreteSnack('warning-alt'),
        info: displayConcreteSnack('info')
      }}
    >
      <ToastContainer
        position={position || toast.POSITION.BOTTOM_RIGHT}
        autoClose={autoClose}
        closeOnClick
        closeButton={false}
        limit={limit}
        hideProgressBar={hideProgressBar}
        style={{ width: 'auto' }}
      />
      {children}
    </SnackBarContext.Provider>
  );
}

export function useSnack() {
  const context = useContext(SnackBarContext);

  if (context === undefined) {
    throw new Error('useSnack must be used within a SnackbarContextProvider');
  }

  return context;
}
