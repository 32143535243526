import { PropsWithChildren } from 'react';

import './Layout.scss';
import EnvironmentBanner from '../components/common/EnvironmentBanner';

export const EmptyLayout = ({ children }: PropsWithChildren) => {
  return (
    <>
      <EnvironmentBanner />
      <div className="app__content-box">{children}</div>
    </>
  );
};
