import { AxiosResponse } from 'axios';
import { UserInterface } from '@partnerconnect-monorepo/types';
import { axios } from '../core';

const getReportingUserProfile = (): Promise<AxiosResponse<UserInterface>> =>
  axios
    .get(`${process.env.NX_REPORTING_APP_BE_BASE}/profile/me`)
    .then((res) => {
      if (res.data.roles) {
        res.data.reporting_roles = [...res.data.roles];
      }
      res.data.reporting_country = res.data.country;

      delete res.data.country;

      return res;
    })
    .catch((err) => {
      return Promise.reject(err);
    });

const getFlaUserProfile = (): Promise<AxiosResponse<UserInterface>> =>
  axios
    .get(`${process.env.NX_FLA_APP_BE_BASE}/profile/me`)
    .then((res) => {
      if (res.data.roles) {
        res.data.fla_roles = [...res.data.roles];
      }
      res.data.partnerships_country = res.data.country;

      delete res.data.country;

      return res;
    })
    .catch((err) => {
      return Promise.reject(err);
    });

export const getUser = () => {
  const reportingUserProfileRequest = getReportingUserProfile();
  const flaUserProfileRequest = getFlaUserProfile();

  return Promise.allSettled([
    reportingUserProfileRequest,
    flaUserProfileRequest
  ]).then((userProfileResponses) => ({
    data: userProfileResponses
      .filter((response) => response.status === 'fulfilled')
      .map(
        (userProfileResponse) =>
          (
            userProfileResponse as PromiseFulfilledResult<
              AxiosResponse<UserInterface>
            >
          ).value.data
      )
      .reduce((acc: any, data: any) => {
        Object.keys(data).forEach((property) => {
          acc[property] ??= data[property];
          if (Array.isArray(data[property])) {
            acc[property] = [
              ...new Set(
                (acc[property] as Array<unknown>).concat(data[property])
              )
            ];
          }
        });

        const reportingProfileStatus = userProfileResponses[0].status;
        const flaProfileStatus = userProfileResponses[1].status;

        acc['allowedModules'] = {
          canAccessPartnerships: flaProfileStatus === 'fulfilled',
          canAccessReporting: reportingProfileStatus === 'fulfilled'
        };

        return acc;
      }, {})
  }));
};
