import jwt_decode from 'jwt-decode';
import { getToken } from '../api';

export const getUserNameFromToken = () => {
  const token = getToken();
  if (token) {
    const tokenData = jwt_decode<Partial<{ given_name: string }>>(token);
    return tokenData?.given_name;
  }

  return '';
};
