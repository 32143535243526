import { Icon } from '@wfp/ui';
import { iconChevronDown } from '@wfp/icons';
import './FlippableChevron.scss';

interface FlippableChevronProps {
  isFlipped: boolean;
}

export const FlippableChevron = ({ isFlipped }: FlippableChevronProps) => (
  <Icon
    className={isFlipped ? 'chevron chevron--rotated' : 'chevron'}
    icon={iconChevronDown}
    description={isFlipped ? 'Chevron up icon' : 'Chevron down icon'}
    width="12"
    height="8"
  />
);
