import { useEffect } from 'react';
import TagManager from 'react-gtm-module';
import { TRACKING_ID } from '../utils/trackingId';
import { useLocation } from 'react-router-dom';
import { useEnv } from '../context';

const PAGE_VIEW_EVENT = 'page_view';
export const usePageTracking = () => {
  const env = useEnv();

  const location = useLocation();

  useEffect(() => {
    if (env.production) {
      TagManager.initialize({ gtmId: TRACKING_ID });
      TagManager.dataLayer({
        dataLayer: {
          event: PAGE_VIEW_EVENT,
          page: location.pathname + location.search
        }
      });
    }
  }, [env.production, location.pathname, location.search]);
};
