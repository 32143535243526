import { ToastNotification } from '@wfp/ui';
import './Toaster.scss';
import { SnackbarProps } from '@partnerconnect-monorepo/types';

export const Toaster = ({
  role,
  kind,
  type,
  caption,
  iconDescription,
  statusIconDescription,
  title,
  subtitle
}: SnackbarProps) => (
  <ToastNotification
    role={role || 'alert'}
    lowContrast
    kind={kind}
    type={type}
    caption={caption || ''}
    iconDescription={iconDescription}
    statusIconDescription={statusIconDescription}
    title={title}
    subtitle={subtitle}
    hideCloseButton
  />
);
