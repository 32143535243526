import { User } from 'oidc-client-ts';

export const getToken = () => {
  const loggedInUser = sessionStorage.getItem(
    `oidc.user:${process.env.NX_REACT_APP_AUTHENTICATION_URL}:${process.env.NX_REACT_APP_AUTHENTICATION_CLIENT_ID}`
  );

  if (loggedInUser) {
    return (JSON.parse(loggedInUser) as User)?.id_token;
  }

  return '';
};
