import { useReportingModuleNotifications } from '../../../api';

export const useNotifications = () => {
  const { data: reportingModuleNotifications, isLoading } =
    useReportingModuleNotifications();

  return {
    reportingModuleNotifications: reportingModuleNotifications?.sort(
      (notificationA, notificationB) =>
        notificationA.code.localeCompare(notificationB.code)
    ),
    isLoading
  };
};
