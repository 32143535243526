import {
  usePageTracking,
  useRoutesContext
} from '@partnerconnect-monorepo/shared';
import { routes } from './routes';

export function App() {
  usePageTracking();
  useRoutesContext(routes);

  return null;
}

export default App;
