import {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useState
} from 'react';
import { IRouteProps } from '@partnerconnect-monorepo/types';

import { independentAppSharedRoutes, sharedRoutes } from '../routes';
import { useEnv } from './environment';

interface RoutesContextProps {
  addRoutes: (r: Array<IRouteProps>) => void;
  routes: Array<IRouteProps>;
  defaultRoute: IRouteProps;
}
const RoutesContext = createContext<RoutesContextProps>({
  routes: sharedRoutes,
  defaultRoute: sharedRoutes[0],
  addRoutes: () => void 0
});

export function RoutesContextProvider({ children }: PropsWithChildren) {
  const { deployedOnRootPath } = useEnv();

  const [combinedRoutes, setCombinedRoutes] = useState<Array<IRouteProps>>(
    deployedOnRootPath ? independentAppSharedRoutes : sharedRoutes
  );
  const [defaultRoute, setDefaultRoute] = useState<IRouteProps>(
    (deployedOnRootPath ? independentAppSharedRoutes : sharedRoutes)[0]
  );

  const addRoutes = (routes: Array<IRouteProps>) => {
    setCombinedRoutes((oldRoutes) => {
      const uniqueRoutes = routes.filter(
        (route) => !oldRoutes.some((old) => old.path === route.path)
      );
      return [...oldRoutes, ...uniqueRoutes];
    });
  };

  useEffect(() => {
    if (combinedRoutes.length) {
      const defaultRouteItem = combinedRoutes.filter(
        (route) => route.isDefault
      );
      if (defaultRouteItem.length > 1) {
        throw new Error(
          `More than one default route!! ${defaultRouteItem
            .map((item) => `"${item.path || ''}"`)
            .join(', ')}`
        );
      }
      if (defaultRouteItem.length) {
        setDefaultRoute(defaultRouteItem[0]);
      }
    }
  }, [combinedRoutes]);

  return (
    <RoutesContext.Provider
      value={{
        addRoutes,
        defaultRoute,
        routes: combinedRoutes
      }}
    >
      {children}
    </RoutesContext.Provider>
  );
}

export function useRoutesContext(routesToAdd?: Array<IRouteProps>) {
  const { addRoutes, routes, defaultRoute } = useContext(RoutesContext);

  useEffect(() => {
    if (routesToAdd) {
      addRoutes(routesToAdd);
    }
  }, []);

  return { routes, defaultRoute };
}
