import { AnnouncementsView } from './AnnouncementsView';
import { useAnnouncements } from './Announcements.hooks';

export const Announcements = () => {
  const { banners } = useAnnouncements();

  if (banners.length === 0) {
    return null;
  }

  return <AnnouncementsView banners={banners} />;
};
