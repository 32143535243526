import { NotFound } from '../NotFound';
import { usePreferences } from './Preferences.hooks';
import { PreferencesView } from './PreferencesView';

export const Preferences = () => {
  const { isPreferencesPageEnabled, activeItem, setActiveItem } =
    usePreferences();

  if (!isPreferencesPageEnabled) {
    return <NotFound />;
  }

  return (
    <PreferencesView activeItem={activeItem} setActiveItem={setActiveItem} />
  );
};
