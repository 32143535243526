import { t } from '@transifex/native';
import { useStepperContext } from './StepperContext';
import { T } from '@transifex/react';
import { Module, ModuleHeader, Text } from '@wfp/ui';
import { useMemo } from 'react';

export const StepperContent = ({
  children: passedChildren
}: {
  children?: JSX.Element | Array<JSX.Element | Array<JSX.Element>>;
}) => {
  const { steps, activeStep } = useStepperContext();

  const children = useMemo(
    () =>
      passedChildren
        ? Array.isArray(passedChildren)
          ? passedChildren.flat()
          : [passedChildren]
        : [],
    [passedChildren]
  );

  const selectedElement = useMemo(() => {
    return children[activeStep] || steps?.[activeStep]?.element;
  }, [children, steps, activeStep]);

  return (
    <Module fullHeight className="module">
      <>
        <ModuleHeader>
          <Text kind="h6">
            <T _str="Step" /> {activeStep + 1}/{steps.length}:{' '}
            {t(steps[activeStep]?.name)}
          </Text>
          <Text className="text-gray font-weight-normal">
            {t(steps[activeStep]?.description)}
          </Text>
        </ModuleHeader>
        {selectedElement || null}
      </>
    </Module>
  );
};
