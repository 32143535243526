import { t } from '@transifex/native';

export const AgeGroup: { [key: string]: string } = {
  '1': t('0-23 months'),
  '2': t('24-59 months'),
  '3': t('5-11 years'),
  '4': t('12-17 years'),
  '5': t('18-59 years'),
  '6': t('60+ years')
};

export const getAgeGroupLabelByID = (id: string) => {
  if (!id) {
    return '';
  }
  return id === '0' ? t('Total') : t(AgeGroup[id]);
};

export const getAgeGroupIDByLabel = (ageGroup: string) =>
  ageGroup
    ? Object.keys(AgeGroup).find((key) => AgeGroup[key] === ageGroup) || ''
    : '';
